<template>
    <div class="fullComponentOfSearchMobile">
        <div class="topTab">
            <img style="width: 30px; height: 30px;" 
            src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/back_button.svg" alt="back-button"
            @click="redirectToPreviousPage()"
            >

            <v-text-field solo flat placeholder="Search" hide-details="auto" background-color="#F4F6FA" 
                class="ma-0 pa-0 search-bar" v-model="valueInSearchBar" 
                @input="updateSearch"
                :value="searchQuery"
                @keyup.enter="enterPressedForSearch(valueInSearchBar)"
            >
                <template #append>
                    <img 
                        style="width: 30px; height: 30px;" @click="enterPressedForSearch(valueInSearchBar)"
                        src="https://s3iconimages.mymedicine.com.mm/search_common_home_icon.svg" alt="Search Icon">
                </template>
            </v-text-field>
        </div>

        <v-divider></v-divider>

        <div class="recent-searches-segment">
            <p class="headingOfSearchComponent">
                {{ $t("Customer.Home.Recent_Searches") }}
            </p>
            <div 
                class="line">
            </div>
            <v-chip-group
                column 
                multiple
                class="searchItemsTab"
            >
                <v-chip
                    v-for="searchItem in reversedQueue()"
                    label
                    link
                    @click="enterPressedForSearch(searchItem)"
                    class="item"
                    :key="searchItem"
                >
                    {{ searchItem }}
                </v-chip>
            </v-chip-group>
        </div>

        <div 
            class="show-search-results-Section" 
            v-if="enterPressed || searchQuery.length>3 || (valueInSearchBar.length>0 && valueInSearchBar.toLocaleLowerCase()==searchResultTopicFromDB.toLocaleLowerCase())" 
            :key="searchQueryComponent">
            
            <v-divider></v-divider>

            <div 
                style="margin-top: 20px !important" 
                v-if="teleconsultationSearchLoading || epharmacySearchingLoading || (searchQuery.length>0 && searchQuery.toLocaleLowerCase()!=searchResultTopicFromDB.toLocaleLowerCase())">
                <v-skeleton-loader elevation="0" type="heading" class="loader-padding"></v-skeleton-loader>
                <v-skeleton-loader elevation="0" type="heading" class="loader-padding"></v-skeleton-loader>
                <v-skeleton-loader elevation="0" type="heading" class="loader-padding"></v-skeleton-loader>
            </div>
            <div v-else class="search-results-segment">
                <!-- Specializations -->
                <div v-if="!teleconsultationSearchLoading && searchSpecializationResultsFromDB && searchSpecializationResultsFromDB.length > 0" class="specializations-section" style="margin-bottom: 15px;">
                        <p class="headingOfSearchComponent">
                            {{ $t("Customer.AllSpecializationsPage.Specializations")}}
                            <span>({{ searchSpecializationResultsFromDB.length }})</span>
                        </p>
                        
                        <div class="line"></div>

                        <div class="listResults" v-for="result in searchSpecializationResultsFromDB">
                            <div class="mx-2 my-2 px-0 listItem d-flex justify-space-between dark-grey-color-text" @click="gotoSpecializationPage(result)">
                                <p class="ma-0 line-clamp result-name-class" align="left">{{ result.specialization_name }}</p>
                                <img src="https://s3iconimages.mymedicine.com.mm/RightArrowBlue_SearchResult.svg">
                            </div>
                            <v-divider
                                v-if="result != searchSpecializationResultsFromDB[searchSpecializationResultsFromDB.length]"
                                class="line2"
                            >
                            </v-divider>
                        </div>
                </div>

                <!-- Doctors -->
                <div v-if="!teleconsultationSearchLoading && searchDoctorResultsFromDB && searchDoctorResultsFromDB.length > 0" class="doctors-section" style="margin-bottom: 15px;">
                    <p class="headingOfSearchComponent">
                        {{ $t("Customer.BookAppointment.Doctors")}}
                        <span>({{ searchDoctorResultsFromDB.length }})</span>
                    </p>
                    
                    <div class="line"></div>

                    <div class="listResults" v-for="result in searchDoctorResultsFromDB">
                        <div class="mx-2 my-2 px-0 listItem d-flex justify-space-between dark-grey-color-text" @click="gotoDoctorPage(result)">
                            <p class="ma-0 line-clamp result-name-class" align="left">{{ result.doctor_name }}</p>
                            <img src="https://s3iconimages.mymedicine.com.mm/RightArrowBlue_SearchResult.svg">
                        </div>
                        <v-divider
                            v-if="result != searchDoctorResultsFromDB[searchDoctorResultsFromDB.length]"
                            class="line2"
                        >
                        </v-divider>
                    </div>
                </div>

                <!-- Products -->
                <div v-if="!epharmacySearchingLoading && searchProductResultsFromDB && searchProductResultsFromDB.length > 0" class="doctors-section" >
                    <p class="headingOfSearchComponent">
                        {{ $t("Customer.MyOrders.Products")}}
                        <span>({{ searchProductResultsFromDB.length }})</span>
                    </p>
                    
                    <div class="line"></div>

                    <div class="listResults" v-for="result in searchProductResultsFromDB">
                        <div class="mx-2 my-2 px-0 listItem d-flex justify-space-between dark-grey-color-text" @click="gotoProductPage(result)">
                            <p class="ma-0 line-clamp result-name-class" align="left">{{ result.name }}</p>
                            <img src="https://s3iconimages.mymedicine.com.mm/RightArrowBlue_SearchResult.svg">
                        </div>
                        <v-divider
                            v-if="result != searchProductResultsFromDB[searchProductResultsFromDB.length]"
                            class="line2"
                        >
                        </v-divider>
                    </div>
                </div>

                <!-- No Search Results -->
                <div v-else-if="searchSpecializationResultsFromDB.length === 0 && searchDoctorResultsFromDB.length === 0 && searchProductResultsFromDB.length === 0">
                    <p class="headingOfSearchComponent">
                        {{ $t("Customer.Home.Search_Results")}}
                        <span>({{ searchSpecializationResultsFromDB.length + searchDoctorResultsFromDB.length + searchProductResultsFromDB.length }})</span>
                    </p>
                    <div class="no-result">
                        <img src="https://s3iconimages.mymedicine.com.mm/NoSearchResultBrowser.svg" alt="No Search Result Image">
                        <p class="no-result-msg">{{ $t("Customer.Home.Sorry_No_Search_Results") }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="shop-by-categories-Section">
        </div>
        
        <div class="frequently-searched-products-Section">
        </div>
    </div>
</template>

<script>
import { axios_auth_instance_customer, axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils';
import axios from "axios";
export default {
    name: "SearchMobilePage",
    props: [

    ],
    data() {
        return {
            skeletonLoader: false,
            searchQuery: '',
            recentSearches: [],
            searchResultTopicFromDB: '',
            searchQueryComponent: 0,
            searchSpecializationResultsFromDB: [],
            searchDoctorResultsFromDB: [],
            searchProductResultsFromDB: [],
            queue: [],
			epharmacySearchingLoading: false,
			teleconsultationSearchLoading: false,
            valueInSearchBar: '',
			enterPressed: false,
            token: null
        }
    },
    mounted() {
        if (window.xm) {
            this.token = localStorage.getItem('customerToken');
        } else {
            this.token = this.$cookies.get('customerToken');
        }
        // Sets the language to one in local storage
        this.$i18n.locale = this.$store.state.locale;

        this.queue = JSON.parse(localStorage.getItem('searchQueue')) || [];
        this.userPageViewTable();
    },
    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'searchPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'searchPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        enterPressedForSearch(value) {
            if (value.length > 0) {
                this.enterPressed = true;
                this.dialogVisible=true;
                this.searchQuery = value;

                this.getAllProductsForSearchQuery(this.searchQuery);
                this.addToRecentSearches();
                this.dialogVisible = true;
            }
		},
        reversedQueue() {
            return this.queue.slice().reverse();
        },
        addToRecentSearches() {
            if (this.queue) {
                let lowerCaseArray = this.queue.map(item => item.toLowerCase());
                if (this.searchQuery != '') {
                    if (!lowerCaseArray.includes(this.searchQuery.toLowerCase())) {
                        this.queue.push(this.searchQuery);

                        if(this.queue.length > 5) {
                            this.queue.shift();
                        }
                        localStorage.setItem('searchQueue', JSON.stringify(this.queue));
                    }
                    else {
                        const index = lowerCaseArray.findIndex(item => item.toLowerCase()===this.searchQuery.toLowerCase());

                        if (index !== -1)
                            this.queue.splice(index, 1);

                        this.queue.push(this.searchQuery);

                        if(this.queue.length > 5) {
                            this.queue.shift();
                        }
                        localStorage.setItem('searchQueue', JSON.stringify(this.queue));
                    }
                } 
            }
            // this.searchQuery = '';
        },
        async getAllProductsForSearchQuery(queryToBeSearched) {
            this.skeletonLoader = true;
            this.epharmacySearchingLoading = true;
			this.teleconsultationSearchLoading = true;

            // Teleconsultation Search.
            let searchDoctorRequest = {
                token: '',
                typeOfUser: 'CUSTOMER',
                searchString: queryToBeSearched
            };

            await axios_auth_instance_customer.post('/searchDoctor', searchDoctorRequest).then((searchDoctorResponse) => {
                this.searchResultTopicFromDB = searchDoctorResponse.data.inputString;
                if (this.searchResultTopicFromDB == queryToBeSearched) {
                    this.searchSpecializationResultsFromDB = searchDoctorResponse.data.data.searchSpl;
                    this.searchDoctorResultsFromDB = searchDoctorResponse.data.data.searchDoc;
                    this.searchQueryComponent++;
                    this.teleconsultationSearchLoading = false;
                }
            }).catch((searchDoctorError) => {
                this.teleconsultationSearchLoading = false;
                console.log('searchDoctorError: ', searchDoctorError);
            });

            // ePharmacy Search.
            await axios_auth_instance_epharmacy_customer.get('/globalSearch', { params: { inputString: queryToBeSearched } }).then((globalSearchResponse) => {
                this.searchResultTopicFromDB = globalSearchResponse.data.inputString;
                this.searchProductResultsFromDB = globalSearchResponse.data.data.searchedProduct ? globalSearchResponse.data.data.searchedProduct : [];

                this.searchQueryComponent++;
                this.epharmacySearchingLoading = false;
            }).catch((globalSearchError) => {
                this.epharmacySearchingLoading = false;
                if (globalSearchError.response.status === 500) {
                    this.searchResultTopicFromDB = globalSearchResponse.data.inputString;
                    this.searchProductResultsFromDB = [];
                }
                console.log('Error fetching common home page data: ', globalSearchError);
                handleError(globalSearchError, this.$router, 'globalSearchError', '', '');
            });
            if(!this.epharmacySearchingLoading && !this.teleconsultationSearchLoading){
				this.analyticsDebounced()
			}
        },
        analyticsDebounced() {
            if (this.sendDataTimeout) clearTimeout(this.sendDataTimeout);
            this.sendDataTimeout = setTimeout(() => {
                this.bigQueryHandler();
            }, 1000);
        },
        async bigQueryHandler() {
            let bigQueryIngestionRequest = null;
			let serviceDoctors =  this.searchDoctorResultsFromDB ? this.searchDoctorResultsFromDB : [];
			let specializations =  this.specializations ? this.specializations : [];
			let products =  this.searchProductResultsFromDB ? this.searchProductResultsFromDB : [];
			this.token = await this.$cookies.get('customerToken');
			bigQueryIngestionRequest = {
				searchString: this.searchQuery,
				serviceDoctors: serviceDoctors,
				specializations: specializations,
				product: products,
				token: this.token ? this.token : '',
				deviceType: 'MiniAPP'
			}
			try {
				let bigQueryHandler = await axios.post(process.env.VUE_APP_BACKEND_URL + '/bigQueryIngestion', bigQueryIngestionRequest);
			} catch (error) {
				console.log('bigQueryWriteError', error);
			}
        },
        gotoSpecializationPage(specialization) {
			this.addToRecentSearches();
			this.searchQuery = '';

			this.$router.push({
				path: '/customer/viewDoctors?type=Category&type1='+ specialization._id + '&type2=' + specialization.specialization_name
			});
		},
		gotoDoctorPage(doctor) {
			this.addToRecentSearches();
			this.searchQuery = '';

			this.$router.push({
                name: 'newBookingPage',
                params: { docId: doctor._id, walkIn: false }
            });
		},
		gotoProductPage(product) {
			this.addToRecentSearches();
			this.searchQuery = '';

            this.$router.push({
                name: 'ProductDetailsPage',
                params: {
                    product_id: product.id
                }
            });
		},
        updateSearch(value) {
            if(value.length > 3) {
                this.searchQuery = value;
				this.searchDoctorResultsFromDB = [],
				this.searchProductResultsFromDB = [],
				this.searchSpecializationResultsFromDB = []
                this.getAllProductsForSearchQuery(this.searchQuery);
            } else if (value.length === 0) {
				this.searchQuery = value;
				this.searchDoctorResultsFromDB = [],
				this.searchProductResultsFromDB = [],
				this.searchSpecializationResultsFromDB = []
			}
        },
        redirectToPreviousPage() {
            this.$router.go(-1);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';
.recent-searches-segment, .search-results-segment {
    padding: 16px 8px;
}
.search-bar {
    margin-left: 8px;
}
.line {
    // margin-left: 20px;
    width: 100px;
    height: 0.8px;
    background-color: #1467BF;
}
.line2 {
	width: 100%;
	height: 1px;
	background-color: #E0E0E0;
    margin-top: 10px;
}
.no-result {
    padding-top: 100px;
}
.no-result-msg {
    padding-top: 20px;
    font-size: 14px;
}
.result-name-class {
    max-width: 80vw;
}
.line-clamp {
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.listItem {
	display: flex;
    justify-content: space-between;
	background-color: white !important; 
	text-transform: none; 
	width: 100%;
    align-items: center;
	font-weight: 400;
	font-size: 14px;
}
.listResults {
	text-align: left;
	color: #1467BF;
    padding-top: 10px;
}
.topTab {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 16px;
}
.headingOfSearchComponent {
    color: #1467BF;
    text-transform: uppercase;
    text-align: start;
    font-weight: 600;
    font-size: $normal-text-font-size-mobile;
}
.fullComponentOfSearchMobile {
    width: 100%;
    background-color: white;
    font-family: $font-family-default;
    padding: 16px 8px;
}
.searchItemsTab {
    padding-top: 8px;
    padding-right: 8px;
}
// .recent-searches-Section {
//     padding-top: 10px;
//     padding-bottom: 20px;
//     // padding-left: 10px;
// }
.item {
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    background-color: #F4F6FA !important;
}
// .search-results-section {
//     padding-bottom: 8px;
//     padding-left: 20px;
// }
.loader-padding {
    padding: 0px 0px 8px 0px;
}
</style>

<style lang="scss">
.listItem.v-btn:not(.v-btn--round).v-size--default {
    padding: unset;
}
</style>